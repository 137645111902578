<template>
  <div class="bind_otp_help" v-html="googleTeachImage"></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BindOTPHelp',
  computed: {
    ...mapState("help", ["helpData"]),
    googleTeachImage() {
      return this.helpData[0].content
    }
  },
}
</script>